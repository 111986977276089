import React from 'react';
import { array, func, string } from 'prop-types';
import PlateItem from './PlateItem';

import css from './ListingPage.module.css';

function PlateGroup(props) {
  const { groupTitle, plates, updateDiningCart } = props;

  return (
    <div className={css.plateGroup}>
      <h3>{groupTitle}</h3>
      {plates.map(plate => (
        <PlateItem updateDiningCart={updateDiningCart} key={plate.id} {...plate} />
      ))}
    </div>
  );
}

PlateGroup.propTypes = {
  groupTitle: string.isRequired,
  plates: array.isRequired,
  updateDiningCart: func,
};

export default PlateGroup;
