import React from 'react';
import { richText } from '../../util/richText';

import css from './ListingPage.module.css';

function SectionMaterialsMaybe(props) {
  const { description, title, longWordMinLength } = props;

  return description ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>{title}</h2>
      <p className={css.description}>
        {richText(description, {
          longWordMinLength,
          longWordClass: css.longWord,
        })}
      </p>
    </div>
  ) : null;
}
export default SectionMaterialsMaybe;
