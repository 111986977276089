import React from 'react';
import { func, object } from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import PlateGroup from './PlateGroup';
import { PLATE_STYLES } from '../../util/listingHelpers';

import css from './ListingPage.module.css';

function SectionMenu(props) {
  const { menu, intl, updateDiningCart } = props;
  const plateGroups = PLATE_STYLES.map(style => {
    const plateItems = menu[style];
    if (!plateItems) {
      return null;
    }

    const styleLabel = intl.formatMessage({ id: `ListingPage.style.${style}` });
    return (
      <PlateGroup
        updateDiningCart={updateDiningCart}
        key={style}
        groupTitle={styleLabel}
        plates={plateItems}
      />
    );
  });

  return (
    <div className={css.sectionMenu}>
      <h2 className={css.menuTitle}>
        <FormattedMessage id="ListingPage.menuTitle" />
      </h2>
      <div>{plateGroups}</div>
    </div>
  );
}

SectionMenu.propTypes = { menu: object.isRequired, updateDiningCart: func };

export default injectIntl(SectionMenu);
