import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { InlineTextButton, NamedLink } from '../../components';

import css from './ListingPage.module.css';

const SectionHeading = props => {
  const { richTitle, showContactUser, onContactUser, author, authorId, isClass } = props;

  return (
    <div className={css.sectionHeading}>
      <div className={css.heading}>
        <h1 className={css.title}>{richTitle}</h1>
        <div className={css.author}>
          <FormattedMessage id="ListingPage.hostedBy" values={{ isClass }} />
          <NamedLink
            className={css.profileLink}
            name="ProfilePage"
            params={{ id: authorId }}
          >
            {author}
          </NamedLink>
          {showContactUser ? (
            <span className={css.contactWrapper}>
              <span className={css.separator}>•</span>
              <InlineTextButton
                rootClassName={css.contactLink}
                onClick={onContactUser}
                enforcePagePreloadFor="SignupPage"
              >
                <FormattedMessage id="ListingPage.contactUser" />
              </InlineTextButton>
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SectionHeading;
