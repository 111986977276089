import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { SkillLevel } from '../../components';
import { getSkillLevelNames, SKILL_LEVELS } from "../../util/listingHelpers";

import css from './ListingPage.module.css';

function SectionSkillLevel(props) {
  const { skillLevel } = props;

  const skillLevelLabel = <FormattedMessage id={`ListingPage.${skillLevel}`} />;
  const skillLevelLength = getSkillLevelNames().length;

  return (
    <div className={css.sectionDuration}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.skillLevelTitle" />
      </h2>
      <div className={css.skillLevel}>
        <SkillLevel className={css.skillLevelIcons} activeLevel={SKILL_LEVELS[skillLevel]} iconSize={40} />
        <span className={css.separator}>•</span>
        {skillLevelLabel}
      </div>
    </div>
  );
}

SectionSkillLevel.propTypes = { skillLevel: string.isRequired };

export default SectionSkillLevel;
