import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.module.css';

function SectionDuration(props) {
  const duration = (
    <FormattedMessage id="ListingPage.duration" values={{ hours: props.duration }} />
  );

  return (
    <div className={css.sectionDuration}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.durationTitle" />
      </h2>
      <p className={css.description}>{duration}</p>
    </div>
  );
}
export default SectionDuration;
