import React from 'react';
import { array, func, object, string } from 'prop-types';
import { injectIntl } from 'react-intl';
import { priceData } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { Button, IconAdd, IconMinus } from '../../components';

import css from './ListingPage.module.css';

const { Money } = sdkTypes;
function PlateItem(props) {
  const { title, allergens, ingredients, price, intl, updateDiningCart, quantity, id } = props;

  function getPriceText(price) {
    const { amount, currency } = price;
    const money = new Money(amount, currency);
    return priceData(money, intl).priceTitle;
  }

  function getAllergensText(allergens) {
    const allergensText = allergens.map(allergen =>
      intl.formatMessage({ id: `ListingPage.allergen.${allergen}` })
    );
    return allergensText.join(', ');
  }

  return (
    <div className={css.menuItem}>
      <div className={css.plateItem}>
        <div className={css.header}>
          <span className={css.plateTitle}>{title}</span>
          <span className={css.plateSeparator}>•</span>
          <span className={css.platePrice}>{getPriceText(price)}</span>
          <span className={css.allergens}>{getAllergensText(allergens)}</span>
        </div>
        <div className={css.ingredients}>{ingredients}</div>
      </div>
      <div className={css.actionsWrapper}>
        <Button className={css.adjustmentButton} onClick={() => updateDiningCart(id, title, -1)}>
          <IconMinus />
        </Button>
        <p className={css.quantity}>{quantity}</p>
        <Button className={css.adjustmentButton} onClick={() => updateDiningCart(id, title, 1)}>
          <IconAdd />
        </Button>
      </div>
    </div>
  );
}

PlateItem.propTypes = {
  title: string.isRequired,
  allergens: array.isRequired,
  ingredients: string.isRequired,
  price: object.isRequired,
  updateDiningCart: func,
};

export default injectIntl(PlateItem);
